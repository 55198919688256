.container {
  --worb-rail-title-color: var(
    --page-theme-contrast-color,
    var(--color-primary)
  );
  --worb-rail-title-font-size: var(--font-size-64px);
}

.container--white-background {
  display: flex; /* HACK. Needed to enforce margin rules on sub components */
  flex-direction: column; /* Just in case more than 1 child, stack them vertically */
  background-color: var(--color-surface-solid-light-lighter);

  --worb-rail-background-color: var(--color-surface-solid-light-lighter);
  --worb-rail-title-color: var(--color-primary);
}

.skeleton {
  background-color: var(--color-bg-shade);
  width: 100%;
  height: 808.77px; /* TODO: why *.77 pixels */
}
