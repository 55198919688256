.container {
  --_worb-rail-background-color: var(
    --worb-rail-background-color,
    var(--page-theme-background-color, var(--color-bg-shade))
  );
  --_worb-rail-title-color: var(
    --worb-rail-title-color,
    var(--page-theme-contrast-color, var(--color-text-heading))
  );
  --_worb-rail-title-font-size: var(--worb-rail-title-font-size);

  margin-top: var(--spacing-top); /* Dev default as part of margin refactor */
  margin-bottom: calc(var(--spacing-bottom) - 34px);
  background-color: var(--_worb-rail-background-color);
  padding-inline: var(--spacing-sides);
  overflow-x: clip;

  @media (width >= 650px) {
    margin-top: 94px;
  }
}

.container--hidden {
  display: none;
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.main {
  display: flex;
  row-gap: 54px;
  flex-direction: column;
}

.header {
  display: flex;
  column-gap: 16px;
}

.title {
  flex: 1;
  color: var(--_worb-rail-title-color);
  font-weight: var(--font-weight-heading);
  font-size: var(--_worb-rail-title-font-size, var(--font-size-24px));
  font-family: var(--font-family-heading);
  text-align: center;
  text-transform: none;

  @media (width >= 650px) {
    font-size: var(--_worb-rail-title-font-size, var(--font-size-36px));
    text-align: initial;
  }
}

.slider-button-wrapper {
  display: none;
  gap: var(--slider-button-wrapper-gap);

  @media (width >= 1200px) {
    display: flex;
  }
}

[data-has-custom-theme] .slider-button[kind="primary"] {
  --cosmos-button-background: var(--theming-button-background);
  --cosmos-button-background-hover: var(--theming-button-background-hover);
  --cosmos-button-color: var(--theming-button-color);
  --cosmos-button-color-hover: var(--theming-button-color-hover);
  --cosmos-button-border-width: var(--theming-button-border-width);
  --cosmos-button-border-color: var(--theming-button-border-color);
  --cosmos-button-border-color-hover: var(--theming-button-border-color-hover);
}

/* `.button::part(base):disabled` does not work in Chrome, so we target the Cosmos web component  */
[data-has-custom-theme] .slider-button[disabled] {
  --cosmos-button-background: color-mix(
    in srgb,
    var(--theming-button-background),
    transparent 45%
  );
  --cosmos-button-color: color-mix(
    in srgb,
    var(--theming-button-color),
    transparent 45%
  );
  --cosmos-button-border-color: transparent;
}

html[dir="rtl"] .slider-button {
  transform: scaleX(-1); /* Flipped for RTL */
}

.viewport {
  margin-left: calc(50% - 50vw);
  padding-inline: var(--spacing-sides);

  /* Inner componends have drop shadows and focus states which get cropped, this is an attempt to stop that */

  /* Break the container out of the wrapper (add a child of`inner` to this...) */
  width: 100vw;
  overflow-x: clip;
}

.embla__viewport {
  width: 100%;
}

.embla__container {
  --card-margin: 12px;
  --cards-per-viewport: 1;

  display: flex;
  column-gap: var(--card-margin);
  touch-action: pan-y pinch-zoom;

  @media (width >= 650px) {
    --card-margin: 20px;
    --cards-per-viewport: 2;
  }

  @media (width >= 1000px) {
    --card-margin: 24px;
    --cards-per-viewport: 3;
  }

  @media (width >= 1200px) {
    --card-margin: 24px;
    --cards-per-viewport: 4;
  }
}

.embla__slide {
  flex: 0 0
    calc(
      ((100% + var(--card-margin)) / var(--cards-per-viewport)) -
        var(--card-margin)
    );
  min-width: 0;
}
